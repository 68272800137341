import request from '@/utils/request';

//推广平台列表
export function getPlatformList(data) {
  return request({
    url: '/clientserver.php/PopularizePlatform/index',
    method: 'post',
    data: data
  });
}
//推广平台列表
export function getPlatformIndex(data) {
  return request({
    url: '/clientserver.php/Common/getPlatform',
    method: 'post',
    data: data
  });
}
//推广平台添加
export function addPlatform(data) {
  return request({
    url: '/clientserver.php/PopularizePlatform/add',
    method: 'post',
    data: data
  });
}
//推广平台编辑
export function editPlatform(data) {
  return request({
    url: '/clientserver.php/PopularizePlatform/exit',
    method: 'post',
    data: data
  });
}
//推广平台删除
export function deletePlatform(data) {
  return request({
    url: '/clientserver.php/PopularizePlatform/delete',
    method: 'post',
    data: data
  });
}
//推广项目列表
export function getProjectList(data) {
  return request({
    url: '/clientserver.php/PopularizeProject/index',
    method: 'post',
    data: data
  });
}
//更改分配方式
export function setUpdateStatus(data) {
  return request({
    url: '/clientserver.php/PopularizeProject/update_status',
    method: 'post',
    data: data
  });
}
//推广项目新增
export function getProjectAdd(data) {
  return request({
    url: '/clientserver.php/PopularizeProject/add',
    method: 'post',
    data: data
  });
}
//推广项目编辑
export function getProjectEdit(data) {
  return request({
    url: '/clientserver.php/PopularizeProject/exit',
    method: 'post',
    data: data
  });
}
//推广项目读取
export function getProjectRead(data) {
  return request({
    url: '/clientserver.php/PopularizeProject/read',
    method: 'post',
    data: data
  });
}
export function getPlatform(data) {
  return request({
    url: '/clientserver.php/Common/getPlatform',
    method: 'post',
    data: data
  });
}
//检测推广项目名称
export function checkNameIsExists(data) {
  return request({
    url: '/clientserver.php/PopularizeProject/checkNameIsExists',
    method: 'post',
    data: data
  });
}
//落地页列表
export function getGroundList(data) {
  return request({
    url: '/clientserver.php/PopularizeGround/index',
    method: 'post',
    data: data
  });
}
//落地页新增
export function getGroundAdd(data) {
  return request({
    url: '/clientserver.php/PopularizeGround/add',
    method: 'post',
    data: data
  });
}
//落地页编辑
export function getGroundEdit(data) {
  return request({
    url: '/clientserver.php/PopularizeGround/exit',
    method: 'post',
    data: data
  });
}
//落地页删除
export function getGroundDelete(data) {
  return request({
    url: '/clientserver.php/PopularizeGround/delete',
    method: 'post',
    data: data
  });
}
//落地页读取
export function getGroundRead(data) {
  return request({
    url: '/clientserver.php/PopularizeGround/read',
    method: 'post',
    data: data
  });
}
//落地页转移
export function getGroundChange(data) {
  return request({
    url: '/clientserver.php/PopularizeGround/change',
    method: 'post',
    data: data
  });
}
//落地页ID重复检测
export function getGroundCheck(data) {
  return request({
    url: '/clientserver.php/PopularizeGround/check',
    method: 'post',
    data: data
  });
}
//撞单对比范围
export function getPromoteCityList() {
  return request({
    url: '/clientserver.php/PromoteCity/getPromoteCityList',
    method: 'post'
  });
}
//推广平台下拉列表
export function getAllProjectName(data) {
  return request({
    url: '/clientserver.php/PopularizeProject/getAllClientProjectName',
    method: 'post',
    data: data
  });
}
//获取参与自动分配的部门
export function getStructure(data) {
  return request({
    url: '/clientserver.php/PopularizeProject/get_structure',
    method: 'post',
    data: data
  });
}
//权重配置列表
export function levelConfigList(data) {
  return request({
    url: '/clientserver.php/LevelConfig/getList',
    method: 'post',
    data: data
  });
}
//权重配置读取
export function levelConfigRead(data) {
  return request({
    url: '/clientserver.php/LevelConfig/read',
    method: 'post',
    data: data
  });
}
//权重配置新增
export function levelConfigAdd(data) {
  return request({
    url: '/clientserver.php/LevelConfig/add',
    method: 'post',
    data: data
  });
}
//权重配置编辑
export function levelConfigUpdate(data) {
  return request({
    url: '/clientserver.php/LevelConfig/update',
    method: 'post',
    data: data
  });
}
//权重配置删除
export function levelConfigDelete(data) {
  return request({
    url: '/clientserver.php/LevelConfig/delete',
    method: 'post',
    data: data
  });
}
//推广数据分配列表
export function PopularizeDistributeIndex(data) {
  return request({
    url: '/clientserver.php/PopularizeDistribute/index',
    method: 'post',
    data: data
  });
}
//流量接受配置列表
export function ReceiveConfigList(data) {
  return request({
    url: '/clientserver.php/FlowReceiveConfig/index',
    method: 'post',
    data: data
  });
}
//推广数据分配详情
export function PopularizeDistributeRead(data) {
  return request({
    url: '/clientserver.php/PopularizeDistribute/read',
    method: 'post',
    data: data
  });
}
//流量接受配置详情
export function ReceiveConfigDetail(data) {
  return request({
    url: '/clientserver.php/FlowReceiveConfig/detail',
    method: 'post',
    data: data
  });
}
//推广数据分配编辑
export function PopularizeDistributeExit(data) {
  return request({
    url: '/clientserver.php/PopularizeDistribute/exit',
    method: 'post',
    data: data
  });
}
//部门流量分配列表
export function PopularizeStructueList(data) {
  return request({
    url: '/clientserver.php/PopularizeStructue/index',
    method: 'post',
    data: data
  });
}
//推广数据分配创建
export function PopularizeDistribute(data) {
  return request({
    url: '/clientserver.php/PopularizeDistribute/add',
    method: 'post',
    data: data
  });
}
//部门流量分配新增
export function PopularizeStructueAdd(data) {
  return request({
    url: '/clientserver.php/PopularizeStructue/add',
    method: 'post',
    data: data
  });
}
//部门流量分配编辑
export function PopularizeStructueEdit(data) {
  return request({
    url: '/clientserver.php/PopularizeStructue/exit',
    method: 'post',
    data: data
  });
}
//部门流量分配获取部门
export function PopularizeStructue(data) {
  return request({
    url: '/clientserver.php/PopularizeStructue/get_structure',
    method: 'post',
    data: data
  });
}
//部门流量分配详情
export function PopularizeStructueRead(data) {
  return request({
    url: '/clientserver.php/PopularizeStructue/read',
    method: 'post',
    data: data
  });
}
//部门流量分配删除
export function PopularizeStructueDelete(data) {
  return request({
    url: '/clientserver.php/PopularizeStructue/delete',
    method: 'post',
    data: data
  });
}
//推广账户列表
export function ExtensionAaccount(data) {
  return request({
    url: '/clientserver.php/ExtensionAaccount/index',
    method: 'post',
    data: data
  });
}
//新增推广账户
export function ExtensionAaccountAdd(data) {
  return request({
    url: '/clientserver.php/ExtensionAaccount/add',
    method: 'post',
    data: data
  });
}
//推广账户检测账号是否授权
export function checkauthorize(data) {
  return request({
    url: '/clientserver.php/ExtensionAaccount/checkauthorize',
    method: 'post',
    data: data
  });
}
//推广账户详情
export function ExtensionAaccountRead(data) {
  return request({
    url: '/clientserver.php/ExtensionAaccount/read',
    method: 'post',
    data: data
  });
}
//推广账户编辑
export function ExtensionAaccountExit(data) {
  return request({
    url: '/clientserver.php/ExtensionAaccount/exit',
    method: 'post',
    data: data
  });
}
//推广账户账户变动详情
export function ExtensionAaccountInfolog(data) {
  return request({
    url: '/clientserver.php/ExtensionAaccount/infolog',
    method: 'post',
    data: data
  });
}
//推广账户账户返点修改
export function ExtensionAaccountEditrebate(data) {
  return request({
    url: '/clientserver.php/ExtensionAaccount/editrebate',
    method: 'post',
    data: data
  });
}
//推广账户更新余额
export function ExtensionAaccountUpdateBalance(data) {
  return request({
    url: '/clientserver.php/ExtensionAaccount/updateBalance',
    method: 'post',
    data: data
  });
}
//推广账户删除
export function ExtensionAaccountDelete(data) {
  return request({
    url: '/clientserver.php/ExtensionAaccount/delete',
    method: 'post',
    data: data
  });
}
//推广分析列表
export function ExtensionAnalysisList(data) {
  return request({
    url: '/clientserver.php/ExtensionAnalysis/list',
    method: 'post',
    data: data
  });
}
//推广分析账户简称
export function get_all_account_abbreviation(data) {
  return request({
    url: '/clientserver.php/Common/get_all_account_abbreviation',
    method: 'post',
    data: data
  });
}
//推广分析推广平台
export function get_one_level_platform(data) {
  return request({
    url: '/clientserver.php/Common/get_one_level_platform',
    method: 'post',
    data: data
  });
}
//推广分析数据创建人
export function get_all_create_user(data) {
  return request({
    url: '/clientserver.php/ExtensionAnalysis/get_all_create_user',
    method: 'post',
    data: data
  });
}
//推广分析学员级别详情
export function PageIdCustomerLevelStatistics(data) {
  return request({
    url: '/clientserver.php/ExtensionAnalysis/pageId_customer_level_statistics',
    method: 'post',
    data: data
  });
}
//推广分析数据分配详情
export function pageIdDistributionStatistics(data) {
  return request({
    url: '/clientserver.php/ExtensionAnalysis/pageId_distribution_statistics',
    method: 'post',
    data: data
  });
}

// 数据回收规则列表
export function SeaRuleList(data) {
  return request({
    url: '/clientserver.php/CustomerRegressionSeaRule/list',
    method: 'post',
    data: data
  });
}
// 数据回收新增三级联动
export function GetConditionList(data) {
  return request({
    url: '/clientserver.php/CustomerRegressionSeaRule/get_condition_list',
    method: 'post',
    data: data
  });
}
// 获取所有规则适用部门
export function GetRegressionStructures(data) {
  return request({
    url: '/clientserver.php/CustomerRegressionSeaRule/get_regression_structures',
    method: 'post',
    data: data
  });
}
// 新增、编辑数据回收规则
export function SeaRuleSet(data) {
  return request({
    url: '/clientserver.php/CustomerRegressionSeaRule/set',
    method: 'post',
    data: data
  });
}
// 读取数据回收规则
export function SeaRuleRead(data) {
  return request({
    url: '/clientserver.php/CustomerRegressionSeaRule/read',
    method: 'post',
    data: data
  });
}
// 删除数据回收规则
export function SeaRuleDel(data) {
  return request({
    url: '/clientserver.php/CustomerRegressionSeaRule/del',
    method: 'post',
    data: data
  });
}

// 获取定级组列表
export function getRankGroupList(data) {
  return request({
    url: '/clientserver.php/PopularizeProject/getRankGroupList',
    method: 'post',
    data: data
  });
}

// 根据组获取部门
export function getRankGroupStructre(data) {
  return request({
    url: '/clientserver.php/PopularizeProject/getRankGroupStructre',
    method: 'post',
    data: data
  });
}